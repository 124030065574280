import React from 'react';
import { ItandiChip, Button } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import Styles from './Block.module.scss';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { PropertySelectModal } from '~/components/common/kensaku_kun/PropertySelectModal/PropertySelectModal';
import { DealParam } from '~/models/DealForm';
import { Property } from '~/models/kensaku_kun/property';

export const RoomBlockPropertySelect: React.FC = () => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const {
    watch,
    formState: { errors },
    setValue,
    trigger
  } = useFormContext<DealParam>();
  const handleSelect = React.useCallback(
    (property: Property<'room' | 'building'>) => {
      handleClose();
      setValue('property', property);
      trigger('property').then();
    },
    [handleClose, setValue, trigger]
  );
  const property = watch('property');
  return (
    <div className={Styles.FormRow}>
      <div className={Styles.FormLabel}>
        <span className={Styles.FormLabelText}>物件選択</span>
        <span className={Styles.RequiredLabel}>
          <ItandiChip label="必須" />
        </span>
      </div>
      <div className={Styles.FormContent}>
        {property === null ? (
          <Button height="small" onClick={handleOpen} variant="secondary">
            物件を選択
          </Button>
        ) : (
          <div>
            <div className={Styles.SelectedPropertyLabel}>
              {property.type === 'building' && (
                <span className={Styles.PropertyTypeLabel}>棟</span>
              )}
              {property.type === 'room' && (
                <span className={Styles.PropertyTypeLabel}>部屋</span>
              )}
              <span>{property.name}</span>
              {property.type === 'room' && <span>{property.roomNumber}</span>}
            </div>
            <div>
              <Button height="small" onClick={handleOpen} variant="secondary">
                物件を変更
              </Button>
            </div>
          </div>
        )}
        {errors.property != null && (
          <div className={Styles.ErrorMessage}>{errors.property.message}</div>
        )}
        <PropertySelectModal
          handleClose={handleClose}
          handleSelect={handleSelect}
          isOpen={isOpen}
        />
      </div>
    </div>
  );
};
